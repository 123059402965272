export function getCookie(name) {
  const cookieArray = document.cookie.split(";")
  const cookieName = name + "="

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim()

    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length)
    }
  }

  return null
}
