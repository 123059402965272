/* eslint-disable no-undef */
export const PRIVATE_URL = process.env.REACT_APP_API_URL
export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL
// export const API_URL = "http://localhost:3333/" + "api/"
export const IMG_URL = process.env.REACT_APP_API_URL_PUBLIC + "img/"



export const ROUTE_LOGIN = "/login"
export const ROUTE_REGISTER = "/register"

export const ROUTE_LOGOUT = "/logout"
export const ROUTE_HOME = "/"


export const ROUTE_ADMIN = "/admin"
export const ROUTE_ADMIN_PROJECT = "/admin/project"
export const ROUTE_ADMIN_PROJECT_ID =  "/admin/project/:id"
export const ROUTE_API_ADMIN_PROJECT = PRIVATE_URL + "projects"
export const ROUTE_API_ADMIN_PROJECT_ID = PRIVATE_URL + "projects/:id"

export const ROUTE_ADMIN_LANDING = "/admin/landing"
export const ROUTE_API_ADMIN_LANDING = PRIVATE_URL + "landing"

export const ROUTE_ADMIN_PARTNER = "/admin/partner"
export const ROUTE_ADMIN_PARTNER_ID =  "/admin/partner/:id"
export const ROUTE_ADMIN_PARTNER_CREATE = "/admin/partner/create"
export const ROUTE_API_ADMIN_PARTNER = PRIVATE_URL + "partners"
export const ROUTE_API_ADMIN_PARTNER_ID = PRIVATE_URL + "partners/:id"

export const ROUTE_USERS = "/admin/users"
export const ROUTE_USERS_ID =  "/admin/users/:id"
export const ROUTE_USERS_CREATE = "/admin/users/create"
export const ROUTE_USERS_UPDATE = "/admin/users/edit/:id"
