import React, { lazy } from "react"

import { ROUTE_LOGIN, ROUTE_HOME, ROUTE_ADMIN, ROUTE_ADMIN_LANDING, ROUTE_ADMIN_PARTNER, ROUTE_ADMIN_PARTNER_ID, ROUTE_ADMIN_PARTNER_CREATE, ROUTE_ADMIN_PROJECT, ROUTE_ADMIN_PROJECT_ID } from "./constants/api"

const AdminPage = lazy(() => import("./pages/admin"))
const LandingPage = lazy(() => import("./pages/admin/landing"))
const PartnerPage = lazy(() => import("./pages/admin/partners"))
const PartnerCreatePage = lazy(() => import("./pages/admin/partners/create"))
const PartnerPageEdit = lazy(() => import("./pages/admin/partners/editPartner"))
const ProjectPage = lazy(() => import("./pages/admin/projects"))
const ProjectPageEdit = lazy(() => import("./pages/admin/projects/editProject"))
const AuthPage = lazy(() => import("./pages/auth"))
const HomePage = lazy(() => import("./pages/app"))

const routes = [
  {
    path: ROUTE_HOME,
    name: "App",
    component: <HomePage />,
    layout: "app",
  },
  {
    path: ROUTE_LOGIN,
    name: "authentication",
    component: <AuthPage />,
    layout: "auth",
  },
  {
    path: ROUTE_ADMIN,
    name: "dashboard",
    component: <AdminPage />,
    layout: "amdin",
  },
  {
    path: ROUTE_ADMIN_LANDING,
    name: "dashboard",
    component: <LandingPage />,
    layout: "admin",
  },
  {
    path: ROUTE_ADMIN_PARTNER,
    name: "admin-partner-page",
    component: <PartnerPage />,
    layout: "admin-partner-project",
  },
  {
    path: ROUTE_ADMIN_PARTNER_ID,
    name: "admin-partner-page-edit",
    component: <PartnerPageEdit />,
    layout: "admin-partner-project",
  },
  {
    path: ROUTE_ADMIN_PARTNER_CREATE,
    name: "admin-partner-page-edit",
    component: <PartnerCreatePage />,
    layout: "admin-partner-project",
  },
  {
    path: ROUTE_ADMIN_PROJECT,
    name: "admin-project-page",
    component: <ProjectPage />,
    layout: "admin-project-project",
  },
  {
    path: ROUTE_ADMIN_PROJECT_ID,
    name: "admin-project-page-edit",
    component: <ProjectPageEdit />,
    layout: "admin-project-project",
  }
]

export default routes
