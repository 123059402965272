import React, { Suspense, useState, useEffect } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { AppContext } from "./contexts/AppContext"
import { getCookie } from "./helpers/cookies"
import "./i18n"
import routes from "./routes"
import { ToastContainer, toast } from "react-toastify"

import "react-toastify/dist/ReactToastify.css"
import "./App.css";



const App = () => {
  const [user, setUser] = useState()
  const [screen, setScreen] = useState()
  const [navigator, setNavigator] = useState()
  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  useEffect(() => {
    const destroyAfterHour = setTimeout(() => {
      sessionStorage.removeItem('token');
    }, 60 * 60 * 1000); 
    return () => {
      clearTimeout(destroyAfterHour);
    };
  }, []);

  useEffect(() => {
    const { screen, navigator } = window
    setScreen(screen)
    setNavigator(navigator)
    if (user) {
      return
    }
    const cookieValue = getCookie("token")
    if (!cookieValue) {
      return
    }

    return
  }, [window])

  return (
    <AppContext.Provider value={{ user, setUser, screen, navigator, isMobile }}>
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            {routes.map((el, index) => (
              <Route
                path={el.path}
                key={el.name + index}
                element={el.component}
              />
            ))}
          </Routes>
        </Suspense>
      </BrowserRouter>
      <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
    </AppContext.Provider>
  )
}

export default App
